<div class="card child-panel rounded text-on-primary border">
  <div class="barcode-container" *ifScreenSize="screenType.Mobile">
    <qrcode [qrdata]="child.CardBarcode" [width]="136" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode>
  </div>
  <div
    class="card-body p-3"
    [ngClass]="{
      'bg-primary': child.AccountPaymentState === 1,
      'bg-upgrade-child': child.AccountPaymentState === 0
    }"
  >
    <div class="d-flex align-items-center">
      <div class="child-panel d-inline-block text-white">
        <div class="m-0 pb-2 fw-bold font-size-16">{{ child.Firstname }} {{ child.Lastname }}</div>
        <p class="m-0 font-size-12">
          {{ 'child.panel.kid.wild.wingers' | translate }}
          <span class="member-id">- {{ child.MemberId }}</span>
        </p>
      </div>
    </div>
    <div class="date-of-birth-wrapper mt-3">
      <label class="mb-1 text-white">{{ 'child.panel.dob.label' | translate : { fgbdefault: 'Date of Birth' } }}</label>
      <div class="d-flex">
        <span class="font-size-14 text-white">
          {{ child.DateOfBirth | date }}
        </span>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center action-panel">
    <ng-container *ngFor="let action of childActions">
      <button type="button" (click)="invoke(action)" class="btn fw-bold" [ngClass]="action.className" *ngIf="action.visible">
        <i class="material-symbols-outlined" *ngIf="action.icon">{{ action.icon }}</i>
        <span> {{ action.websiteContent | translate }}</span>
      </button>
    </ng-container>
  </div>
</div>
