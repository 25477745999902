<div class="banner d-flex">
  <div class="banner-logo"></div>
</div>

<!--Barcode-->
<div class="d-flex" *ifScreenSize="screenType.Mobile">
  <div class="container mt-4">
    <fgb-barcode></fgb-barcode>
  </div>
</div>

<div class="d-flex flex-column">
  <!-- Announcements -->
  <div class="container announcement-container">
    <fgbcl-announcements></fgbcl-announcements>
  </div>

  <div class="container voucher-wrapper my-4">
    <fgb-vouchers></fgb-vouchers>
  </div>
</div>

<!--Scorecards-->
<div class="challenges-container">
  <fgbcl-scorecard-carousel [icon]="'chevron_right'" [showAllView]="true" [phaseThree]="true"></fgbcl-scorecard-carousel>
</div>

<!--Rewards-->
<div class="pb-4 rewards-container" *inPopulation="[populations.Orange, populations.Partner, populations.Kids]">
  <div class="container">
    <ng-container *inPopulation="[populations.Orange, populations.Partner]">
      <ng-container
        [ngTemplateOutlet]="rewardsTemplate"
        [ngTemplateOutletContext]="{ $implicit: 'home.page.marketplace.title' | translate }"
      >
      </ng-container>
    </ng-container>
    <ng-container *inPopulation="[populations.Kids]">
      <ng-container
        [ngTemplateOutlet]="rewardsTemplate"
        [ngTemplateOutletContext]="{ $implicit: 'home.page.kids.featured.rewards.title' | translate }"
      >
      </ng-container>
    </ng-container>
  </div>
</div>

<div class="pb-4 rewards-container" *inPopulation="[populations.Partial]">
  <div class="container">
    <ng-container *ngIf="martketplaceItems$ | async as eventItems">
      <ng-container *ngIf="eventItems.length">
        <div class="events-carousel mt-4">
          <div class="d-flex align-items-center mb-2">
            <h2 class="heading-title my-0">{{ 'featured.events' | translate }}</h2>
            <div class="d-flex align-items-center cursor-pointer ms-auto featured-events-text" [routerLink]="'/rewards/events'">
              <span class="scorecard-featured-link">{{ 'featured.rewards.show.all' | translate }}</span>
              <span class="icon material-icons ps-2 scorecard-featured-link-icon">arrow_forward_ios</span>
            </div>
          </div>
          <owl-carousel-o [options]="customOptions()" (dragging)="isDragging = $event.dragging">
            <ng-container *ngFor="let eventItem of eventItems">
              <ng-template carouselSlide [width]="eventItems.length <= 2 ? rewardsWidth : 0">
                <fgbcl-marketplace-list-item
                  [marketplaceItem]="eventItem"
                  [marketplaceItemTypes]="[3]"
                  [ignoreLink]="isDragging"
                ></fgbcl-marketplace-list-item>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #rewardsTemplate let-title>
  <div class="container">
    <ng-container *ngIf="martketplaceItems$ | async as marketplaceItems">
      <ng-container *ngIf="marketplaceItems.length">
        <div class="events-carousel mt-4">
          <div class="d-flex align-items-center mb-2">
            <h2 class="heading-title my-0">{{ title }}</h2>
            <div
              class="d-flex align-items-center cursor-pointer ms-auto featured-events-text"
              [routerLink]="'/rewards/marketplace'"
            >
              <span class="scorecard-featured-link">{{ 'featured.rewards.show.all' | translate }}</span>
              <span class="icon material-icons ps-2 scorecard-featured-link-icon">arrow_forward_ios</span>
            </div>
          </div>
          <owl-carousel-o [options]="customOptions()" (dragging)="isDragging = $event.dragging">
            <ng-container *ngFor="let marketplaceItem of marketplaceItems">
              <ng-template carouselSlide [width]="marketplaceItems.length <= 2 ? rewardsWidth : 0">
                <fgbcl-marketplace-list-item
                  [marketplaceItem]="marketplaceItem"
                  [marketplaceItemTypes]="[3]"
                  [ignoreLink]="isDragging"
                ></fgbcl-marketplace-list-item>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<div class="container card-container mb-3" *inPopulation="[populations.Orange, populations.Partial]">
  <a class="card rounded border-0" [href]="'home.page.giveaways.link' | translate" target="_blank">
    <label>
      <i class="material-symbols-outlined me-2">award_star</i>
      <span>{{ 'guaranteed.giveaways.title' | translate }}</span>
    </label>
    <span>{{ 'find.out.more' | translate }}</span>
  </a>
  <a class="card rounded border-0" [href]="'home.game.exchange.link' | translate" target="_blank">
    <label>
      <i class="material-symbols-outlined me-2">confirmation_number</i>
      <span> {{ 'future.game.exchange.title' | translate }}</span>
    </label>
    <span>{{ 'find.out.more' | translate }}</span>
  </a>
</div>

<div class="video-container">
  <ng-container *ngIf="worksheets$ | withLoading | async as worksheets">
    <div *ngFor="let worksheetGroup of worksheets.data">
      <ng-container *ngIf="worksheetGroup.Items?.length > 0">
        <div class="container video-wrapper mb-4" *ngFor="let item of worksheetGroup.Items">
          <fgb-worksheet-item [worksheetItem]="item" [worksheetId]="worksheetGroup.WorksheetId"></fgb-worksheet-item>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

<div class="container card-container mb-3" *inPopulation="[populations.Kids]">
  <a class="card rounded border-0" [routerLink]="'/worksheet'">
    <label>
      <i class="material-symbols-outlined me-2">draw</i>
      <span>{{ 'guaranteed.activities.title' | translate }}</span>
    </label>
    <span>{{ 'find.out.more' | translate }}</span>
  </a>
  <a class="card rounded border-0" [routerLink]="'/gallery'">
    <label>
      <i class="material-symbols-outlined me-2">note_stack</i>
      <span> {{ 'future.game.gallery.title' | translate }}</span>
    </label>
    <span>{{ 'find.out.more' | translate }}</span>
  </a>
</div>

<ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.games.leaderboard'">
  <div class="container">
    <h2 class="heading-title my-2">{{ 'home.games.leaderboard.title' | translate }}</h2>
    <iframe
      src="
  https://tradablebits.com/tb_live/508225
  "
      style="border: 0; height: 400px; width: 100%"
      frameborder="0"
      scrolling="no"
    >
    </iframe>
  </div>
</ng-container>
